<template>
    <div>
      <div class="header">
        <van-nav-bar
          :title="$t('tibi')"
          left-arrow
          @click-left="$router.go(-1)"
        />
      </div>
  
      <div>
        <div class="titleTv">{{ $t("WithdrawalAmount") }}</div>
        <div class="inputV">
          <!-- <span style="color: #333; font-size: 1rem;">{{ $t("fuhao") }}</span> -->
          <van-field
            :placeholder="$t('PleaseWithdrawalAmount')"
            type="number"
            v-model="form.number"
            @input="onMoneyInputClick"
            clearable
          ></van-field>
        </div>
        <div class="subV">
          <span class="spanTv1">{{ $t("AccountBalance") }}：{{ balance }}</span>
          <span class="spanTv2" @click.prevent="all">{{ $t("tix.allbtn") }}</span>
        </div>
        <van-divider />
        <van-cell
          :title="$t('WithdrawalTo')"
          :value="$t('SelectWalletAddress')"
          is-link
          @click="onSeleteClick()"
        />
        <div class="cardV" v-if="cardItem">
          <span class="cardCode">{{ cardItem.type }}</span>
          <span class="cardName">{{ cardItem.address }}</span>
        </div>
        <div class="lineV" v-if="cardItem"></div>
        <div class="passwordV">
          <span class="passwordTv1">{{ $t("WithdrawalPassword") }}</span>
          <div class="passwordTv2">
            <van-field
              :placeholder="$t('VerifyPassword')"
              type="password"
              v-model="form.pay_pwd"
              maxlength="6"
              clearable
            ></van-field>
          </div>
        </div>
        <div style="margin-left: 1rem; margin-right: 1rem;">
          <van-button
            class="confirmTv"
            :loading="loading"
            :loading-text="$t('jia-zai-zhong')"
            @click="submit"
          >
            {{ $t("Confirm") }}
          </van-button>
        </div>
  
        <div class="htmlTitleTv">
          {{ $t("WithdrawalInstructions") }}
        </div>
  
        <div class="htmlTv">
          <p v-html="txtHtml"></p>
        </div>
      </div>
    </div>
  </template>
  <script>
  let itemCard = null;
  export default {
    data() {
      return {
        cardItem: null,
        balance: "",
        form: {
          pay_pwd: "",
          number: '',
        },
        loading: false,
        txtHtml:''
      };
    },
    beforeRouteEnter(to, from, next) {
      itemCard = from.params.user;
  
      next();
    },
    created() {
      console.log('--初始化--')
      this.getinfo();
      this.getPayTxt();
      if (itemCard) {
        this.cardItem = JSON.parse(itemCard);
      }
      if(sessionStorage.getItem("withdrawMoeny")){
        this.form.number = sessionStorage.getItem("withdrawMoeny");
        sessionStorage.removeItem("withdrawMoeny");
      }
    },
    methods: {
      onMoneyInputClick(e){
        if(e==""){
          sessionStorage.removeItem("withdrawMoeny");
        }else{
          sessionStorage.setItem("withdrawMoeny",e);
        }
      },
      //获取余额
      async getinfo() {
        const { data } = await this.$http.get("/home/user/withdraw");
        if (data) {
          if (data.code === 200) {
            this.balance = data.data.balance;
          }
        }
      },
       //获取提现说明
       async getPayTxt() {
        const { data } = await this.$http.get("/home/index/payText?id=2");
        if (data) {
          if (data.code === 200) {
            if(localStorage.getItem("lang") == "en"){
              this.txtHtml = data.data[0].en_content;
            }else if(localStorage.getItem("lang") == "ru"){
              this.txtHtml = data.data[0].ru_content;
            }else if(localStorage.getItem("lang") == "zh"){
              this.txtHtml = data.data[0].zh_content;
            }else if(localStorage.getItem("lang") == "fr"){
              this.txtHtml = data.data[0].fr_content;
            }else if(localStorage.getItem("lang") == "it"){
              this.txtHtml = data.data[0].it_content;
            }else {
              this.txtHtml = data.data[0].es_content;
            }
          }
        }
      },
      all() {
        this.form.number = this.balance;
      },
      onSeleteClick() {
        this.$router.push({ path: "/assets/WalletAddress/" + 2 });
      },
      async submit() {
        console.log(this.productvalue);
        if (!this.cardItem|| !this.form.pay_pwd || !this.form.number) {
          this.$toast.fail(this.$t("tix.complate"));
          return false;
        }
        const postform = {
          pay_pwd: this.form.pay_pwd,
          withdraw_num: this.form.number,
          card_id: this.cardItem.id,
        };
        this.loading = true;
        const { data } = await this.$http.post("/home/user/withdraw", postform);
        if (data) {
          if (data.code === 200) {
            this.$toast.success(this.$t("tix.submitok"));
            this.$router.go(-1);
          } else {
            this.$toast.fail(
              this.getlang(data.msg, localStorage.getItem("lang"))
            );
          }
        }
        this.loading = false;
      },
    },
  };
  </script>
  <style lang="less" scoped>
  .titleTv {
    font-family: "Alibaba PuHuiTi";
    font-style: normal;
    font-weight: 700;
    color: #333;
    font-size: 1rem;
    padding-left: 1rem;
    padding-top: 70px;
    text-align: left;
  }
  
  .inputV {
    height: 2.81rem;
    margin-left: 1rem;
    margin-right: 1rem;
    background: #f6f6f6;
    border-radius: 0.43rem;
    margin-top: 0.75rem;
    display: flex;
    align-items: center;
    padding-left: 0.62rem;
    padding-right: 0.62rem;
    font-size: 1rem;
    margin-bottom: 0.63rem;
  
    .van-field {
      color: #333;
      font-size: 1rem;
      background-color: #00000000;
  
      input {
        color: #333;
      }
      /deep/.van-field__control {
        text-align: left;
      }
    }
  }
  
  .subV {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    .spanTv1 {
      font-size: 0.75rem;
      color: #777;
    }
  
    .spanTv2 {
      font-size: 0.75rem;
      color: #363636;
      font-weight: 600;
    }
  }
  
  .van-divider {
    margin-top: 0.75rem;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 0rem;
    border-color: #e6e6e6;
  }
  
  .van-cell {
    padding-top: 1.5rem;
    padding-bottom: 1.25rem;
    border-bottom: 0px;
    .van-cell__title {
      font-family: "Alibaba PuHuiTi";
      font-style: normal;
      font-weight: 400;
      color: #333;
      text-align: left;
    }
    .van-cell__value {
      font-family: "Alibaba PuHuiTi";
      font-style: normal;
      font-weight: 700;
      color: #333;
    }
  }
  
  .cardV {
    height: 3.5rem;
    margin-left: 1rem;
    margin-right: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 10px;
    box-shadow: 0 0 1.33333rem #999;
  
    .cardCode {
    color: #323232;
    font-size: 15px;
    text-align: left;
  }

  .cardName {
    color: #999;
    font-size: 10px;
    margin-top: 0.2rem;
    text-align: left;
  }
  }
  
  .lineV {
    width: 100%;
    height: 0.5px;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 1.56rem;
    background: #e6e6e6;
  }
  
  .passwordV {
    display: flex;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 10px;
    .passwordTv1 {
      flex: 1;
      text-align: left;
      color: #333;
      font-size: 0.93rem;
    }
    .passwordTv2 {
      flex: 1;
      color: #333;
      font-size: 0.93rem;
  
      /deep/.van-cell{
        padding-top: 10px;
        padding-bottom: 10px;
      }
  
      input {
      }
  
      /deep/.van-field__control {
        text-align: end;
      }
    }
  }
  .confirmTv {
    margin-top: 6.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    font-size: 1.12rem;
    color: #fff;
    height: 2.75rem;
    width: 100%;
    background: #f0b82d;
  }
  
  .htmlTitleTv {
    color: #333;
    font-size: 0.87rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1.25rem;
    font-family: "Alibaba PuHuiTi 2.0";
    font-style: normal;
    font-weight: 700;
    text-align: left;
    padding-bottom: 0.62rem;
  }
  
  .htmlTv {
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: start;
  }
  </style>
  